<template>
  <div class="login-box sty1">
    <div class="login-box-body sty1">
      <div class="login-logo">
        <router-link :to="{name: 'login'}"><img src="/img/logo-blue.png" alt=""></router-link>
      </div>
      <p class="login-box-msg">Sign in to start your session</p>
      <form>
        <div class="form-group has-feedback">
          <input type="email"
                 v-model="form.email"
                 class="form-control sty1"
                 placeholder="Email">

          <v-errors :errors="errorFor('email')"></v-errors>
        </div>
        <div class="form-group has-feedback">
          <input type="password"
                 v-model="form.password"
                 class="form-control sty1"
                 placeholder="Password">

          <v-errors :errors="errorFor('password')"></v-errors>
        </div>
        <div>
          <!-- /.col -->
          <div class="col-xs-4 m-t-1">
            <button type="submit"
                    @click="login"
                    :disabled="loading"
                    class="btn btn-primary btn-block btn-flat">Sign In</button>
          </div>
          <!-- /.col -->
        </div>
      </form>
      <div class="social-auth-links text-center">
        <p>- OR -</p>
<!--        <a href="#"
           id="facebook_btn"
           @click.prevent="loginWithFacebook"
           class="btn btn-block btn-social btn-facebook btn-flat">
          <i class="fa fa-facebook p-1"></i> Sign in using Facebook</a>-->

        <div class="fb-login-button"
             data-width="360px"
             data-size="large"
             data-scope="email"
             data-auto-logout-link="false"
             data-use-continue-as="true" />

<!--        <a href="#" id="google-login" class="btn btn-block btn-social btn-google btn-flat">
          <i class="fa fa-google p-1"></i> Sign in using Google
        </a>-->

        <div class="mt-2" id="google_btn" />


      </div>
      <!-- /.social-auth-links -->

      <div class="m-t-2">Don't have an account?
        <router-link :to="{name: 'signup'}" class="text-center">Sign Up</router-link>
      </div>
    </div>
    <!-- /.login-box-body -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      googleAuth: null,
      form: {
        device: 'web',
        email: '',
        password: ''
      }
    }
  },
  mounted() {
    this.initGoogleAuth();
  },
  methods: {
    login() {
      this.loading = true;
      this.allErrors = null;

      this.$store.dispatch('auth/login', this.form)
          .then(() => {
            this.$router.push({name: 'workspace'})
          })
          .catch((err) => this.allErrors = err.errors)
          .finally(() => {
            this.loading = false;
          })
    },
    socialLogin(form) {
      this.$store.dispatch('auth/socialLogin', form)
          .then(() => {
            this.$router.push({name: 'workspace'})
          })
          .catch((err) => this.allErrors = err.errors)
          .finally(() => {
            this.loading = false;
          })
    },
    loginWithFacebook() {
      let self = this;

      window.FB.login(function(response) {
        if (response.authResponse) {
          window.FB.api('/me', function(response) {
            let form = {
              provider: 'facebook',
              device: 'web',
              id: response.id,
              email: response.email,
              name: response.name
            }

            self.socialLogin(form);
          });
        }
      }, {
        scope: 'email'
      });
    },
    initGoogleAuth() {
      let self = this;

      window.google.accounts.id.initialize({
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        context: 'signin',
        ux_mode: 'popup',
        auto_prompt: false,
        callback: self.handleGoogleCredentialResponse
      });

      const parent = document.getElementById('google_btn');
      window.google.accounts.id.renderButton(parent, {
        theme: "filled_blue",
        width: '360px'
      });
    },
    handleGoogleCredentialResponse(response) {
      let user = this.parseJwt(response.credential);

      let form = {
        provider: 'google',
        device: 'web',
        id: user.sub,
        email: user.email,
        name: user.name
      }

      this.socialLogin(form);
    },
    parseJwt (token) {
      let base64Url = token.split('.')[1];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    }
  }
}
</script>