<template>
  <div class="login-box">
    <div class="login-box-body">
      <h3 class="login-box-msg mb-3">Select Workspace</h3>

      <div class="alert alert-info" v-if="!fetchingWorkspaces && !workspaces.length">
        No Workspace Found.
      </div>

      <ul class="list-group" v-else>
        <li class="list-group-item d-flex justify-content-between align-items-center"
            v-for="workspace in workspaces"
            :key="'workspace_'+workspace.id">
          {{ workspace.name }}
          <button class="btn btn-sm btn-outline-primary"
                  @click="selectWorkspace(workspace)">Select</button>
        </li>
      </ul>

      <button class="btn btn-sm btn-block btn-info mt-3"
              @click.stop="$refs.newWorkspaceModal.show()">Create New</button>
    </div>
    <!-- /.login-box-body -->
  </div>

  <new-workspace-modal @created="loadWorkspaces"
                       ref="newWorkspaceModal" />
</template>

<script>
import NewWorkspaceModal from "@/views/auth/NewWorkspaceModal.vue";
export default {
  components: {NewWorkspaceModal},
  data() {
    return {
      workspaces: [],
      fetchingWorkspaces: false,
    }
  },
  created() {
    this.loadWorkspaces()
  },
  methods: {
    loadWorkspaces() {
      this.fetchingWorkspaces = true;

      this.axios.get('/workspaces')
          .then(res => {
            this.workspaces = res.data.data;
          })
          .finally(() => {
            this.fetchingWorkspaces = false;
          })
    },
    selectWorkspace(workspace) {
      this.$store.dispatch('auth/selectWorkspace', workspace)
      window.location.replace("/");
    }
  }
}
</script>