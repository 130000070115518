<template>
  <div class="modal fade" id="new-workspace-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Create Workspace</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <input type="text"
                 v-model="form.name"
                 class="form-control"
                 placeholder="Workspace Name">

          <v-errors :errors="errorFor('name')"></v-errors>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Close</button>
          <button type="button"
                  :disabled="loading"
                  @click="createWorkspace"
                  class="btn btn-sm btn-success">Create</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VErrors from "@/components/ValidationError.vue";

export default {
  components: {VErrors},
  data() {
    return {
      form: {
        name: ''
      }
    }
  },
  methods: {
    show() {
      this.form.name = '';
      this.allErrors = null;

      window.$('#new-workspace-modal').modal('show')
    },
    hide() {
      window.$('#new-workspace-modal').modal('hide')
    },
    createWorkspace() {
      this.loading = true;
      this.allErrors = null;

      this.axios.post('/workspaces', this.form)
          .then(() => {
            this.$emit('created');
            this.hide();
          })
          .catch((err) => {
            this.allErrors = err.response.data.errors;
          })
          .finally(() => {
            this.loading = false;
          })
    }
  }
}
</script>