<template>
  <div class="login-box sty1">
    <div class="login-box-body sty1">
      <div class="login-logo">
        <router-link :to="{name: 'signup'}"><img src="/img/logo-blue.png" alt=""></router-link>
      </div>
      <p class="login-box-msg">Sign in to start your session</p>
      <form>
        <div class="form-group has-feedback">
          <input type="text"
                 v-model="form.name"
                 class="form-control sty1"
                 placeholder="Name">

          <v-errors :errors="errorFor('name')"></v-errors>
        </div>
        <div class="form-group has-feedback">
          <input type="email"
                 v-model="form.email"
                 class="form-control sty1"
                 placeholder="Email">

          <v-errors :errors="errorFor('email')"></v-errors>
        </div>
        <div class="form-group has-feedback">
          <input type="password"
                 v-model="form.password"
                 class="form-control sty1"
                 placeholder="Password">

          <v-errors :errors="errorFor('password')"></v-errors>
        </div>
        <div class="form-group has-feedback">
          <input type="password"
                 v-model="form.password_confirmation"
                 class="form-control sty1"
                 placeholder="Conform Password">
        </div>
        <div>
          <!-- /.col -->
          <div class="col-xs-4 m-t-1">
            <button type="submit"
                    :disabled="loading"
                    @click="register"
                    class="btn btn-primary btn-block btn-flat">Sign Up</button>
          </div>
          <!-- /.col -->
        </div>
      </form>


      <div class="m-t-2">Already have an account?
        <router-link :to="{name: 'login'}" class="text-center">Sign In</router-link>
      </div>
    </div>
    <!-- /.login-box-body -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        device: 'web',
        name: '',
        email: '',
        password: '',
        password_confirmation: ''
      }
    }
  },
  methods: {
    register() {
      this.loading = true;
      this.allErrors = null;

      this.$store.dispatch('auth/register', this.form)
          .then(() => {})
          .catch((err) => this.allErrors = err.errors)
          .finally(() => {
            this.loading = false;
          })
    }
  }
}
</script>